<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :width="500"
    :persistent="loading"
    :loading="loading"
  >
    <v-card tile shaped>
      <v-card-title
        class="font-weight-semibold"
        :class="`${titleColor}--text`"
        >{{ title }}</v-card-title
      >
      <v-divider class="my-0" />
      <v-card-text class="py-4">
        <div class="text-body-1 text-pre-wrap">{{ message }}</div>
      </v-card-text>
      <v-card-actions class="d-flex py-6 justify-end" style="gap: 0.75rem">
        <cz-button
          v-if="!hideCancelButton"
          :disabled="loading"
          text
          :min-width="100"
          color="info"
          class="px-4"
          @click="$emit('cancel')"
          >{{ $t('common.cancel') }}</cz-button
        >
        <cz-button
          color="info"
          @click="$emit('done')"
          dark
          :min-width="100"
          class="px-4"
          :loading="loading"
          >{{ doneButtonTitle ?? $t('common.close') }}</cz-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CzButton from './CzButton.vue';
export default {
  name: 'CzPromptDialog',
  components: {
    CzButton
  },
  props: {
    title: {
      type: String
    },
    titleColor: {
      type: String,
      default: 'textPrimary'
    },
    message: {
      type: String
    },
    doneButtonTitle: {
      type: String
    },
    loading: {
      type: Boolean
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
    // can be info, success, warning, error
    state: {
      type: String,
      default: 'info'
    }
  }
};
</script>

<style lang="scss" scoped></style>
