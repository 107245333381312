import { getField, updateField } from 'vuex-map-fields';
import {
  getVendorPrimaryCategories,
  getVendorSecondaryCategories,
  countOrderSubmissions,
  countOrders,
  getVendors,
  createVendor
} from '@/core/api';
import cloneDeep from 'lodash.clonedeep';

const state = {
  vendors: [],
  vendor: null,
  primaryCategories: null,
  secondaryCategories: null,
  ordersCount: null,
  submissionsCount: null,
  filtersState: {
    tab: -1,
    filters: null,
    options: {}
  }
};

const getters = {
  getField
};

const mutations = {
  updateField
};

const actions = {
  resetFiltersState({ commit }) {
    commit('updateField', {
      path: 'filtersState',
      value: {
        tab: -1,
        filters: null,
        options: {}
      }
    });
  },
  async loadVendors({ state, commit, rootState }) {
    const { data: vendors } = await getVendors({
      accountId: rootState.auth?.accountId,
      $populate: ['files']
    });

    commit('updateField', {
      path: 'vendors',
      value: vendors
    });
  },
  setSelectedVendor({ state, commit }, vendorId) {
    if (!state.vendors?.length) {
      return false;
    }
    const selectedVendor = state.vendors.find((item) => item._id === vendorId);
    if (selectedVendor) {
      commit('updateField', {
        path: 'vendor',
        value: selectedVendor
      });
      window.localStorage.setItem('currentVendorId', selectedVendor._id);
      return true;
    } else {
      const currentVendorId = window.localStorage.getItem('currentVendorId');
      if (currentVendorId) {
        const vendor = state.vendors.find(
          (item) => item._id === currentVendorId
        );
        if (vendor) {
          commit('updateField', {
            path: 'vendor',
            value: vendor
          });
        } else {
          commit('updateField', {
            path: 'vendor',
            value: state.vendors[0]
          });
          window.localStorage.setItem('currentVendorId', state.vendors[0]._id);
        }
      } else {
        commit('updateField', {
          path: 'vendor',
          value: state.vendors[0]
        });
        window.localStorage.setItem('currentVendorId', state.vendors[0]._id);
      }

      return false;
    }
  },
  async createVendor({ state, commit }, vendor) {
    const newVendor = await createVendor(vendor);
    const _vendors = cloneDeep(state.vendors);
    _vendors.push(newVendor);
    commit('updateField', {
      path: 'vendors',
      value: _vendors
    });

    return newVendor;
  },
  async switchVendor({ state, commit }, vendorId) {
    const vendor = state.vendors.find((item) => item._id === vendorId);
    if (vendor) {
      commit('updateField', {
        path: 'vendor',
        value: vendor
      });
      window.localStorage.setItem('currentVendorId', vendor._id);
    }
  },
  async loadCategories({ state, commit }) {
    if (state.primaryCategories && state.secondaryCategories) {
      return;
    }

    const [primaryCategoriesRes, secondaryCategoriesRes] = await Promise.all([
      getVendorPrimaryCategories({
        $sort: { _id: 1 }
      }),
      getVendorSecondaryCategories({
        $sort: { _id: 1 }
      })
    ]);

    commit('updateField', {
      path: 'primaryCategories',
      value: primaryCategoriesRes.data
    });

    commit('updateField', {
      path: 'secondaryCategories',
      value: secondaryCategoriesRes.data
    });
  },
  async countOrders({ state, commit, rootState }) {
    const accountId = rootState.auth?.accountId;
    if (!accountId || !state.vendor) {
      return null;
    }

    const ordersCount = await countOrders({
      vendorId: state.vendor._id
    });

    commit('updateField', {
      path: 'ordersCount',
      value: ordersCount
    });
  },
  async countSubmissions({ state, commit, rootState }) {
    const accountId = rootState.auth?.accountId;
    if (!accountId || !state.vendor) {
      return null;
    }

    const submissionsCount = await countOrderSubmissions({
      accountId,
      vendorId: state.vendor._id
    });

    commit('updateField', {
      path: 'submissionsCount',
      value: submissionsCount
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
