const messages = {
  he: {
    $vuetify: {
      dataTable: {
        itemsPerPageText: 'שורות בעמוד:'
      },
      dataFooter: {
        pageText: '{0} - {1} מ- {2}'
      }
    },
    terms: {
      readAndUnderstand: 'קראתי והבנתי',
      confirmButton: 'אישור'
    },
    roles: {
      customerAdmin: 'מנהל פורטל',
      customerPurchaser: 'דורש רכש',
      customerAuthorizer: 'מורשה חתימה',
      customerObserver: 'צופה',
      customerCatalogAdmin: 'מנהל קטלוג',
      vendor: 'ספק',
      customerAccountManager: 'מנהל/ת חשבונות'
    },
    common: {
      add: 'הוסף',
      confirm: 'אישור',
      delete: 'מחיקה',
      resetFilters: 'איפוס מסננים',
      usersSearch: 'חפש משתמש לפי שם או כתובת מייל',
      remove: 'הסר',
      save: 'שמירה',
      saveDraft: 'שמירת טיוטה',
      miloUser: 'מיילו - משתמש אוטומציה',
      miloUserHint:
        'מיילו הינו משתמש מערכת בפורטל של CorZ הדואג ליצור דברים במערכת בצורה אוטומטית עבורך',
      dataSavedSuccess: 'הנתונים נשמרו',
      total: 'סה״כ',
      vat: 'מע״מ',
      automations: 'אוטומציות',
      documentType: 'סוג מסמך',
      attachedDocs: 'מסמכים מצורפים ({0})',
      documentUploadedAt: 'הועלה ב-{0}',
      additionalDocuments: 'מסמכים נוספים',
      additionalDocumentFile: 'קובץ מסמך',
      totalWithVat: 'סה״כ כולל מע״מ',
      update: 'עדכון',
      selectValue: 'בחר ערך',
      appTooltip: 'פורטל הספקים של CorZ',
      actions: 'פעולות',
      unableToSaveOrder: 'לא ניתן לשלוח או להפעיל הזמנה',
      unableToSendSubmission: 'לא ניתן לשלוח הגשה זו',
      invalidCalcVat: 'המע״מ שהוזן אינו תואם למע״מ שחושב',
      netAmountZero: 'סכום ההזמנה חייב להיות גדול מאפס',
      vatExempt: 'המע״מ עבור ספק זה חייב להיות אפס',
      vatCannotBeZero: 'המע״מ עבור ספק זה חייב להיות מעל אפס',
      invalidTotalAmountCalc: 'סכום כולל לא שווה לסכום לפני מע״מ בתוספת מע״מ',
      previewUnavailable: 'תצוגת מסמך לא פעילה',
      contactSupport: 'יצירת קשר עם תמיכה',
      homepage: 'דף הבית',
      next: 'הבא',
      moreActions: 'פעולות נוספות',
      prev: 'הקודם',
      selectDate: 'בחר תאריך',
      na: 'לא קיים',
      urlHint: 'יש להזין כתובת מלאה עם http(s)',
      filters: 'סינון',
      filterBarButtonTitle: 'בצע סינון',
      clearAllFilters: 'נקה הכל',
      autocomplete: 'התחל הקלדה לקבלת ערכים',
      filtersBarTitle: 'סינון רשומות',
      errorOccured: 'אירעה שגיאה',
      itemsPerPage: 'שורות בעמוד:',
      search: 'חיפוש',
      pageText: '{0} - {1} מ- {2}',
      next: 'הבא',
      prev: 'הקודם',
      notice: 'שים לב בבקשה',
      close: 'סגירה',
      cancel: 'ביטול',
      congrats: 'מזל טוב!',
      pageNotFound: 'הדף אינו זמין',
      emptyStateDefaultMessage: 'לא נמצאו נתונים',
      approve: 'אישור',
      reject: 'דחייה',
      return: 'החזרה',
      loading: 'טוען...',
      homepage: 'ראשי',
      noData: 'אין נתונים',
      yes: 'כן',
      continue: 'המשך',
      show: 'צפייה',
      hide: 'סגירה',
      email: 'כתובת דוא״ל',
      password: 'סיסמה',
      assignmentNumber: 'מספר הקצאה',
      fullName: 'שם מלא',
      gender: 'מין',
      terms: 'תנאי שימוש ומדיניות הפרטיות',
      saveChanges: 'שמירת שינויים',
      tags: 'תגיות',
      note: 'הערה',
      note2: 'הערה: {0}',
      grabLinkInfo: 'הקישור הועתק',
      download: 'הורדה',
      downloadSuccessMessage: 'הקובץ הורד בהצלחה',
      documentPredictHint:
        'מסמך שעבר עיבוד באמצעות בינת מלאכותית על מנת לנסות ולהוציא פרטים רלוונטיים בצורה אוטומטית ללא התערבות בן אנוש.\nמומלץ לבצע השוואה מול המסמך המקורי בצד שמאל על מנת לוודא שהפרטים נכונים.',
      fromDate: 'מתאריך',
      toDate: 'עד תאריך',
      addToFavorites: 'הוספה למועדפים',
      removeFromFavorites: 'הסרה מהמועדפים',
      vendorAddedToFavoritesSucess: 'הספק {0} התווסף לרשימת המועדפים שלך',
      vendorRemovedToFavoritesSucess: 'הספק {0} הוסר מרשימת המועדפים שלך',
      workflowApproval: 'סבב אישורים',
      searchOrders: 'חיפוש הזמנות',
      viewDocument: 'לצפייה במסמך'
    },
    dropzone: {
      title: 'גרור קובץ או',
      selectButtonTitle: 'בחר',
      readonlyTitle: 'לחץ לצפייה'
    },
    currency: {
      ILS: 'שקל ישראלי',
      USD: 'דולר אמריקאי',
      EUR: 'אירו',
      GBP: 'לירה שטרלינג (פאונד)'
    },
    auth: {
      profilePicture: 'תמונת פרופיל',
      profileSaved: 'הפרופיל נשמר בהצלחה',
      logout: 'התנתקות מהמערכת',
      termsAndPrivacyPolicyInfo: '',
      termsAndPrivacyPolicy: 'תנאי שימוש ומדיניות הפרטיות',
      logoutMessage: 'האם אתה בטוח שברצונך להתנתק?',
      editProfile: 'עריכת פרופיל',
      email: 'כתובת אימייל',
      enterEmail: 'הזן את כתובת דוא״ל שלך',
      enterLoginPassword: 'הזן את הסיסמה איתה נרשמת',
      enterLoginEmail: 'הזן את כתובת המייל איתה נרשמת',
      forgotEmail: 'שכחתי כתובת מייל',
      forgotEmailInfo:
        'במידה ושכחת את כתובת המייל איתה נרשמת למערכת יש ליצור קשר עם התמיכה שלנו בכתובת:',
      password: 'סיסמה',
      signupWhere: 'היכן נרשמים?',
      signupWhereInfo:
        'הרשמה לפורטל שלנו יכולה להתבצע בשלוש דרכים:\n1. קבלת הזמנה מלקוח קיים בפורטל.\n2. קבלת הזמנה מאחד מאנשי צוות של הפורטל\n3. במידה והינך ספק נותן שירותים או לקוח שעובד עם ספקים נותני שירותים ואתה מעוניין להצטרף או לשמוע על הפורטל שלנו, יש ליצור קשר בכתובת:',
      cofirmedPassword: 'אישור סיסמה',
      resetPasswordButton: 'לחץ לאיפוס סיסמה',
      choosePassword: 'בחר סיסמה',
      choosePasswordToUse: 'בחר סיסמה לשימוש בפורטל',
      forgotPassword: 'שכחתי סיסמה',
      resetPassword: 'איפוס סיסמה',
      passwordNotMatched: 'הסיסמאות אינן זהות אחת לשנייה',
      resetPasswordSentMessage:
        'בקשתך לאיפוס סיסמה התקבלה בהצלחה. יש לגשת ל-תיבת דוא״ל שלך להמשך.',
      resetPasswordSuccessMessage:
        'הסיסמה שונתה בהצלחה, יש לחזור לדף כניסה על מנת להתחבר מחדש לפורטל',
      backToLogin: 'חזרה למסך כניסה',
      signMeUp: 'רשום אותי',
      alreadyHaveAccount: 'יש לך כבר חשבון?',
      alreadyHaveAccountLogin: 'יש לך כבר חשבון? התחבר',
      notAMember: 'אין לך חשבון?',
      signupNow: 'הרשם עכשיו',
      login: 'התחבר',
      fullName: 'שם מלא',
      companyName: 'שם החברה / העסק',
      verification: 'אימות',
      verificationCode: 'קוד אימות',
      verificationCodeHint: 'יש להזין קוד אימות בן 6 ספרות',
      verify: 'בצע אימות',
      reverify: 'לא קיבלת קוד? שלח שוב',
      verificationCodeSent: 'קוד האימות נשלח בהצלחה',
      verficiationComplete: 'האימות הושלם בהצלחה!',
      verficiationCompleteMessage: 'הינך מועבר לדף הבית...',
      verificationFailedMessage: 'האימות נכשל, יש לנסות שנית.'
    },
    social: {
      facebookUrl: 'כתובת דף פייסבוק',
      instagramUrl: 'כתובת פרופיל אינסטגרם',
      linkedInUrl: 'כתובת דף בלינקדאין',
      twitterUrl: 'כתובת דף טוויטר',
      websiteUrl: 'כתובת אתר אינטרנט',
      whatsappPhoneNum: 'מספר WhatsApp עסקי'
    },
    validators: {
      required: '{0} הוא שדה חובה',
      email: 'דואר אלקטרוני אינו תקין',
      phone: 'יש להזין מספר סלולארי ישראלי תקין',
      url: 'כתובת האתר אינה תקינה',
      passwordStrength: 'הסיסמה אינה חזקה מספיק',
      passwordStrengthHint:
        'הסיסמה חייבת להיות מורכבת ממספרים ואותיות ובאורך של לפחות שישה תווים',
      fullName: 'יש להזין שם מלא (שם פרטי ושם משפחה)',
      domain: 'יש להזין דומיין תקין (domain.com)',
      invalidDate: 'תאריך לא תקין',
      presentDate: 'יש להזין תאריך של היום או תאריך עתידי',
      futureDate: 'יש להזין תאריך עתידי',
      length: '{0} חייב להיות באורך {1} תווים בדיוק',
      fileTypeNotSupported: 'הקובץ אינו נתמך',
      maxCharacters: 'מספר התווים המקסימלי בשדה {0} הוא {1}',
      minCharacters: 'מספר התווים המינימלי בשדה {0} הוא {1}',
      minValue: 'המספר המינימלי הוא {0}',
      maxValue: 'המספר לא יכול לחרוג מ-{0}'
    },
    vendor: {
      taxDocsUpdated: 'המסמכים עודכנו בהצלחה ונשלחו לאישור',
      taxDocsExpiredMessage:
        'תוקף אישורי המס שלך פג, יש להעלות אישורים בתוקף בהקדם האפשרי',
      taxDocsUploadButtonText: 'לחץ כאן להעלאת המסמכים',
      submissionCurrency: 'מטבע הגשה',
      submissionStatus: 'סטטוס הגשה',
      signup: {
        title: 'רישום ספק חדש',
        businessName: 'שם הספק',
        vendorNumber: 'מספר ישות (ח.פ./ע.מ./ע.ר.)',
        vendorAddress: 'כתובת העסק',
        vendorPhone: 'טלפון'
      },
      updateDocsDialog: {
        title: 'עדכון מסמכי מס',
        uploadButtonTitle: 'שלח מסמכים לאישור',
        downloadInfo: 'לחץ כאן להורדת המסמכים מאתר רשות המיסים'
      },
      certification: {
        bookKeepingClearance: 'אישור ניהול ספרים בתוקף',
        accountingBooksCertification: 'אישור ניכוי מס במקור בתוקף',
        accountingBooksCertificationExpirationDate: 'תוקף אישור ניכוי מס במקור',
        taxDeductionAuthorization: 'אישור ניהול פנקסי חשבונות בתוקף',
        proofOfBankAccountOwnership: 'אישור ניהול חשבון בנק / צילום צ׳ק',
        businessLicense: 'רישיון עסק בתוקף (אם נדרש)'
      },
      login: {
        title: 'כניסה לפורטל'
      },
      onboarding: {
        status: 'סטטוס',
        vendorDetails: 'פרטי ספק',
        vendorAdditionalDetails: 'פרטים נוספים',
        vendorClassification: 'סיווג העסק',
        vendorDocuments: 'העלאת מסמכים',
        documents: 'מסמכים',
        summery: 'סיכום והגשה',
        businessName: 'שם העסק',
        businessNameHint: 'שם העסק כפי שמופיע בחשבונית',
        businessId: 'מספר יישות (ח.פ./ע.מ./ע.ר.)',
        phone: 'טלפון סלולארי',
        documentsMail: 'מייל לקבלת מסמכים ומידע',
        businessType: 'סוג היישות',
        businessGeneralInfo: 'תיאור העסק',
        businessContactInfo: 'פרטי התקשרות',
        contactPersonName: 'שם איש קשר',
        address: 'כתובת',
        street: 'רחוב',
        city: 'עיר',
        postalCode: 'מיקוד',
        primaryCategory: 'קטגוריה ראשית',
        secondaryCategory: 'קטגוריה משנית',
        choosePrimaryCategoryFirst: 'יש לבחור קודם קטגוריה ראשית',
        choosePrimaryCategory: 'בחר קטגוריה ראשית',
        chooseSecondaryCategory: 'בחר קטגורה משנית',
        vendorDescription: 'תיאור העסק (למשל: סוג השירותים וכו׳)',
        submitVendorButtonTitle: 'שלח לאישור',
        submitVendorTitle: 'שליחה לאישור',
        submitVendorMessage:
          'במידה וכל הפרטים אומתו על ידך.\nניתן כעת ללחוץ על כפתור שליחה לאישור.',
        submitVendorInfoCaption: 'הצוות שלנו יטפל בבקשה בימים הקרובים',
        submitSucceedTitle: 'אנחנו על זה!',
        submitSucceedMessage:
          'אנחנו על זה. בימים הקרובים יגיע אישור לתיבת דוא״ל שלך.',
        returnedReasonTitle: 'סיבת ההחזרה',
        returnedReasonInstructions:
          'לאחר תיקון, יש לעבור לשלב הבא ולבצע הגשה חוזרת',
        accountingBooksCertificationTitle: 'ניכוי מס במקור',
        otherDocuments: 'מסמכים אחרים'
      },
      home: {
        newVendor: 'לחץ ליצירת ספק חדש',
        newVendorTitle: 'יצירת ספק חדש',
        vendorSwitchSuccess: 'הספק הוחלף בהצלחה ל-{0}',
        otherVendors: 'לחץ על ספק להחלפה',
        ordersTitle: 'הזמנות',
        ordersSubmissionsTitle: 'הגשות',
        mySubmissionsTitle: 'ההגשות שלי',
        welcomeMessage: 'ברוך הבא לפורטל הספקים החדשני של Corz',
        newVendorText: 'לחץ כאן כדי להתחיל',
        editVendorText: 'לחץ להמשך הקמה',
        pendingVendorText: 'ממתין לאישור',
        returnedVendorText: 'הוחזר להשלמת פרטים',
        rejectedVendorText: 'נדחה ע״י מנהל',
        activeOrdersTitle: 'הזמנות פתוחות',
        activeOrdersInfo: 'הזמנות פעילות שנשלחו מלקוחות עבורך',
        closedOrdersTitle: 'הזמנות סגורות',
        closedOrdersInfo: 'הזמנות סגורות שנוצלו במלואן או שפג תוקפן',
        uploadInvoice: 'העלאת חשבונית',
        activeOrdersCount: '{0} הזמנות פתוחות',
        expiredOrdersCount: '{0} הזמנות סגורות',
        uploadInvoiceInfo: 'העלאת חשבונית עבור הזמנה קיימת בלבד',
        newSubmission: 'הגשה חדשה',
        newSubmissionInfo: 'לחץ כאן כדי ליצור הגשה חדשה עבור הזמנה קיימת'
      },
      orders: {
        orderTitle: 'כותרת הזמנה',
        orderDescription: 'תיאור',
        amountLeft: 'יתרת הזמנה',
        quantityReport: 'דיווח כמויות',
        customerName: 'שם הלקוח',
        createOrderSubmission: 'לחץ ליצירת הגשה',
        newSubmission: 'הגשה חדשה',
        newSubmissionInfo: 'הגשה חדשה עבור הזמנה {0}',
        orderInvoiceDocument: 'חשבונית עבור הזמנה',
        uploadingDocument: 'מעלה מסמך...',
        processingDocument: 'מבצע עיבוד למסמך...',
        reviewSubmissionTitle: 'ניהול הגשה עבור הזמנה {0}',
        submissionDocument: 'מסמך חשבונית להזמנה',
        submissionItems: 'דיווח כמויות',
        submissionSummaryAndSend: 'סיכום ושליחה',
        orderDocument: 'מסמך הזמנה',
        orderSubmissionDetailsForm: 'פרטים כלליים',
        submissionAdditionalDocuments: 'מסמכים נוספים',
        submissionNumber: 'מספר הגשה',
        documentNumber: 'מספר מסמך',
        status: 'ססטוס',
        saveOnly: 'שמירת טיוטה',
        saveAndSend: 'שמירה ושליחה',
        cancelSubmission: 'ביטול הגשה'
      },
      orderSubmissions: {
        vendorInfo: 'מידע על הספק',
        createSubmissionForSubmission: 'יצירת הגשה מסוג {0} עבור הגשה {1}',
        createChildSubmission: 'יצירת הגשה מתוך הגשה',
        submissionDocumentType: 'סוג מסמך',
        documentNumber: 'מספר מסמך',
        grabSubmissionLink: 'העתק קישור להגשה',
        submissionCompletedAt: 'ההגשה אושרה ונקלטה בתאריך {0}',
        submissionsTitle: 'הגשות',
        submissionTitle: 'הגשה {0}',
        inProgressSubmissions: 'הגשות בהכנה',
        inProgressSubmissionsInfo: 'הגשות שכרגע בהכנה ועדיין לא נשלחו לטיפול',
        pendingSubmissions: 'הגשות בטיפול',
        pendingSubmissionsInfo: 'הגשות שנשלחו ונמצאות בטיפול',
        returnedSubmissions: 'הגשות שהוחזרו',
        returnedSubmissionsInfo: 'הגשות שהוחזרו מלקוחות ומחכות לטיפול על ידך',
        mySubmissions: 'ההגשות שלי',
        mySubmissionsInfo:
          'לחץ לצפייה וניהול של הגשות שאושרו, הוחזרו או ממתינות לאישור',
        closedSubmissions: 'הגשות שטופלו',
        closedSubmissionsInfo: 'הגשות שאושרו או נדחו על ידי הלקוח',
        approvedSubmissions: 'הגשות שאושרו',
        allSubmissions: 'כל ההגשות',
        approvedSubmissionsInfo: 'הגשות שאושרו ונקלטו על ידי הלקוח',
        rejectedSubmissions: 'הגשות שנדחו',
        rejectedSubmissionsInfo: 'הגשות שנדחו על ידי הלקוח',
        totalAmountExceed: 'סכום החשבונית: {0} חורג מסכום יתרת ההזמנה: {1}',
        shortDescription: 'תיאור קצר',
        viewDocuments: 'צפייה במסמכים',
        additionalDocuments: 'מסמכים נוספים',
        attachedDocuments: 'מסמכים מצורפים ({0})',
        dispalyFullSubmission: 'הגשת אב',
        dispalyFullSubmissionLink: 'לחץ לצפייה בהגשת האב',
        customerName: 'שם הלקוח',
        netAmount: 'סכום',
        vatAmount: 'מע״מ',
        totalAmount: 'סכום כולל',
        relatedOrder: 'הזמנה',
        parentSubmission: 'הגשת אב',
        issueDate: 'תאריך הוצאה',
        createdAt: 'תאריך יצירה',
        creationDate: 'תאריך יצירה',
        businessId: 'מספר יישות (ח.פ./ע.מ./ע.ר.)',
        contactPersonName: 'שם איש קשר',
        contactPersonEmail: 'כתובת מייל איש קשר',
        contactPersonPhone: 'מספר טלפון איש קשר',
        actions: 'פעולות',
        editSubmissionTooltip: 'ערוך הגשה',
        cancelSubmissionTooltip: 'ביטול הגשה',
        viewSubmissionTooltip: 'צפייה בהגשה',
        cancelSubmissionTitle: 'ביטול הגשה מספר {0}',
        cancelSubmissionMessage: 'האם אתה בטוח שברצונך לבטל את הגשה זו?',
        cancelSubmissionActionTitle: 'בטל הגשה',
        submissionCancelledSuccesMessage: 'ההגשה בוטלה בהצלחה',
        submissionSavedSuccesMessage: 'ההגשה עודכנה בהצלחה',
        submissionSentSuccesMessage: 'ההגשה עודכנה ונשלחה בהצלחה ללקוח לטיפול',
        additionalDocumentFile: 'קובץ מסמך',
        additionalDocumentTypeLabel: 'סוג מסמך',
        addtionalDocumentTitle: 'כותרת המסמך',
        addAddtionalDocument: 'הוסף מסמך',
        additionalDocumentTypePlaceholder: 'בחר סוג מסמך',
        additionalDocumentTypes: {
          performanceReport: 'דו״ח ביצוע',
          subContactInvoiceCopy: 'העתק חשבונית - קבלני משנה',
          reference: 'אסמכתא',
          bookKeepingClearance: 'אישור ניהול ספרים',
          taxDeductionAuthorization: 'אישור ניכוי מס במקור',
          deliveryCertification: 'תעודת משלוח'
        },
        documentUploadedAt: 'הועלה ב-{0}',
        readOnlyMode: 'מצב קריאה בלבד',
        status: {
          new: 'הגשה חדשה',
          new_predicted: 'הגשה חדשה שעברה מילוי אוטומטי',
          new_predicted_hint:
            'הזמנה שעברה עיבוד באמצעות בינת מלאכותית על מנת לנסות ולהוציא פרטים רלוונטיים בצורה אוטומטית ללא התערבות בן אנוש.\nמומלץ לבצע השוואה מול המסמך המקורי בצד שמאל על מנת לוודא שהפרטים נכונים.',
          returned: 'הגשה הוחזרה ע״י לקוח',
          returnedHint:
            'הגשה אשר הוחזרה ע״י לקוח מסיבה מסויימת.\nיש לבצע תיקון להגשה בהתאם לסיבת ההחזרה ולשלוח מחדש',
          pending_customer_autorizer: 'ממתין לאישור מורשה חתימה',
          pending_customer_purchaser: 'ממתין לאישור מחלקת רכש',
          pending_approval: 'ממתין לאישור לקוח',
          rejected: 'הגשה נדחתה ע״י לקוח',
          rejectedHint:
            'הגשה אשר נדחתה ע״י הלקוח.\nיש לראות סיבת דחייה ולבצע הגשה חדשה',
          approved: 'הגשה אושרה'
        },
        pendingApprovalBy: 'ממתין לאישור ע״י {0}',
        quantityInCurrent: 'כמות בדיווח נוכחי',
        quantityForCredit: 'זיכוי כמות',
        totalInCurrent: 'סה״כ לדיווח נוכחי',
        enterQuantity: 'הזן כמות',
        quantityReportAlertMessage:
          'על מנת לדווח כמויות, יש לוודא שסכום החשבונית גדול מאפס',
        submissionReportedAmount: 'סכום דיווח נוכחי:',
        submissionLeftAmount: 'יתרת חשבונית:',
        sendSubmissionButtonTitle: 'לחץ כאן לשליחת ההגשה'
      },
      submissionDocTypes: {
        'transaction-account': 'חשבון עסקה (דרישת תשלום)',
        invoice: 'חשבונית מס',
        'invoice-receipt': 'חשבונית מס קבלה',
        receipt: 'קבלה',
        'credit-invoice': 'חשבונית זיכוי'
      }
    },
    admin: {
      homeTitle: 'ממשק ניהול',
      manageVendorsTitle: 'ניהול ספקים',
      manageCustomersTitle: 'ניהול לקוחות',
      searchVendors: 'חיפוש ספקים',
      approveVendor: 'אישור הספק',
      returnVendor: 'החזרה להשלמת פרטים',
      rejectVendor: 'דחיית הספק',
      modifyVendorId: 'שינוי ח.פ. עבור ספק',
      modifyVendorIdInfo: 'לחץ לשינוי מספר ח.פ. של ספק קיים במערכת',
      modifyVendorIdSuccess: 'מספר הח.פ. עודכן בהצלחה',
      manageAccountWorkflow: 'ניהול סבבי אישורים',
      manageAccountFeaturesTitle: 'עריכת תכונות פעילות',
      vendorCategoriesTitle: 'ניהול קטגוריות ספק',
      vendorCategoriesSubtitle: 'לחץ כאן להוספה ועדכון קטגוריות עבור ספקים',
      categoryId: 'מזהה קטגוריה',
      categoryName: 'שם קטגוריה',
      createNewCategory: 'הוספת קטגוריה חדשה',
      createNewSubCategory: 'הוספת תת קטגוריה',
      updateExistingCategory: 'עדכון קטגוריה קיימת',
      updateExisitingSubCategory: 'עדכון תת קטגוריה קיימת',
      createCategory: 'צור קטגוריה',
      updateCategory: 'עדכן קטגוריה',
      categoryCreatedSuccess: 'הקטגורה {0} נוצרה בהצלחה!',
      searchCategoriesHint: 'חיפוש לפי שם קטגוריה ראשית',
      categoryUpdateSuccess: 'הקטגוריה עודכנה בהצלחה.',
      subCategoryCreatedSuccess: 'תת הקטגוריה {0} נוצרה בהצלחה.',
      subCategoryUpdateSuccess: 'תת הקטגוריה עודכנה בהצלחה.',
      subCategories: 'תתי קטגוריות עבור {0}',
      customerPrefix: 'מזהה לקוח',
      customerPrefixHint:
        'מזהה הלקוח חייב להיות חד חד ערכי ובאותיות באנגלית בלבד ללא רווחים',
      manageAccountFeaturesDialog: {
        title: 'עריכת תכונות עבור הלקוח {0}',
        successMessage: 'התכונות עבור הלקוח {0} נשמרו בהצלחה'
      },
      accountFeatures: {
        order_creation: 'יצירת הזמנות ידנית',
        order_creation_with_items: 'יצירת הזמנות ידנית כולל שורות',
        digital_archive_attachments: 'ארכיון דיגיטלי עבור קבצים מצורפים',
        digital_archive_orders: 'ארכיון דיגיטלי עבור הזמנות',
        digital_archive_submissions: 'ארכיון דיגיטלי עבור חשבוניות',
        invoices: 'הגשות במייל',
        orderless: 'הגשות במייל'
      },
      removeFeatureDialog: {
        title: 'הסרת תכונה מחשבון לקוח',
        message: 'האם אתה בטוח שברצונך להסיר את "{0}" מהחשבון של הלקוח: {1}?',
        successMessage: 'התכונה {0} הוסרה בהצלחה מהחשבון {1}'
      },
      modifyVendorIdDialog: {
        fromVendorId: 'בחר ספק קיים',
        toVendorId: 'הקלד מספר ח.פ. חדש',
        actionButtonTitle: 'בצע שינוי ח.פ.'
      },
      workflowTypes: {
        orderApprovalWorkflow: 'סבב אישורים עבור הזמנה',
        orderSubmissionWorkflow: 'סבב אישורים עבור חשבונית',
        accountManagementWorkflow: 'סבב אישורים עבור הנהלת חשבונות'
      },

      workflowManagementTitle: 'ניהול סבבי אישורים',
      workflowManagementInfo: 'לחץ לניהול סבבי אישורים עבור הלקוחות',
      accountWorkflowDefaults: {
        operators: {
          equal: 'שווה',
          greaterThan: 'גדול מ',
          lessThan: 'קטן מ',
          between: 'טווח'
        },
        selectField: 'שדה',
        addCondition: 'הוסף תנאי',
        operator: 'אופרטור',
        valueFrom: 'ערך מ-',
        valueTo: 'ערך עד',
        value: 'ערך'
      },
      workflowManagement: {
        setDefaults: 'הגדר ברירות מחדל',
        setDefaultsDialogTitle: 'הגדרת ברירות מחדל עבור {0}',
        selectCustomer: 'בחר לקוח',
        createNewWorkflow: 'יצירת סבב אישורים חדש',
        deleteWorkflow: 'מחיקת סבב אישורים',
        deleteWorkflowMessage: 'האם אתה בטוח שברצונך למחוק סבב אישורים זה?',
        deleteWorkflowButtonTitle: 'מחק סבב אישורים',
        deleteWorkflowStepTitle: 'מחיקת שלב בסבב אישורים',
        deleteWorkflowStepMessage:
          'האם אתה בטוח שברצונך למחוק את השלב ״{0}״ בסבב האישורים ״{1}״?',
        deleteWorkflowStepButtonTitle: 'מחק שלב',
        manageWorkflowTitle: 'סבבי אישורים עבור {0}',
        accountWorkflowCreatedSuccess: 'סבב אישורים נוצר בהצלחה עבור לקוח {0}',
        accountWorkflowStepCreatedSuccess:
          'השלב {0} נוצר עבור סבב האישורים: {1}',
        accountWorkflowStepDeletedSuccess: 'השלב נמחק בהצלחה',
        workflowDialog: {
          workflowName: 'שם סבב האישורים',
          workflowType: 'סוג סבב האישורים',
          createWorkflowButton: 'צור סבב אישורים'
        },
        workflowStepDialog: {
          title: 'יצירת שלב חדש',
          stepName: 'שם השלב',
          stepNumber: 'מספר השלב',
          stepType: 'סוג השלב',
          approverRole: 'תפקיד מאשר השלב',
          saveStepButtonTitle: 'שמירה'
        },
        workflowHeaders: {
          name: 'שם סבב האישורים',
          type: 'סוג סבב האישורים',
          createdBy: 'נוצר ע״י',
          stepsCount: 'מספר שלבים בסבב'
        },
        workflowStepsHeaders: {
          stepNumber: 'מספר השלב',
          stepTitle: 'שם השלב',
          stepType: 'סוג השלב',
          approverRole: 'תפקיד המאשר'
        }
      },
      tabs: {
        pending: 'ממתינים לאישור',
        approved: 'אושרו',
        rejected: 'נדחו',
        returned: 'הוחזרו'
      },
      maunal_order_creation: 'יצירת הזמנות ידנית',
      digital_archive: 'ארכיון דיגיטלי',
      invoices: 'חשבוניות',
      accountActiveFeatures: {
        order_creation: 'יצירת הזמנות ידנית',
        order_creation_with_items: 'יצירת הזמנות ידנית כולל שורות',
        digital_archive_attachments: 'ארכיון דיגיטלי עבור קבצים מצורפים',
        digital_archive_orders: 'ארכיון דיגיטלי עבור הזמנות',
        digital_archive_submissions: 'ארכיון דיגיטלי עבור חשבוניות',
        invoices: 'הגשות במייל'
      },
      pendingVendors: 'ספקים ממתינים לאישור',
      approvedVendors: 'ספקים שאושרו',
      rejectedVendors: 'ספקים שנדחו',
      draftVendors: 'ספקים בתהליך הקמה',
      returnedVendors: 'ספקים שהוחזרו',
      inviteVendor: 'הזמנת ספק',
      inviteVendorInfo: 'לחץ להזמנת ספק חדש למערכת',
      manageVendors: 'ניהול ספקים',
      adminHomePage: 'דף הבית',
      vendorApprovedMessage: 'הספק אושר בהצלחה',
      rejectDecisionTitle: 'דחיית הספק',
      rejectDecisionMessage: 'אנא הזן את סיבת הדחייה',
      returnDecisionTitle: 'החזרה לספק',
      returnDecisionMessage: 'אנא הזן את סיבת ההחזרה',
      vendorRejectedMessage: 'הספק נדחה בהצלחה',
      vendorReturnedMessage: 'פעולת ההחזרה לספק בוצעה בהצלחה!',
      lastUpdatedBy: 'עודכן ע״י {0}',
      lastUpdatedAt: 'ב- {0}',
      reason: 'סיבה',
      vendorSubmissionHistory: 'לחץ לצפייה בהיסטורית הגשה',
      additionalDetailsTooltip: 'לחץ לפרטים נוספים',
      createNewCustomer: 'הקמת לקוח חדש',
      createNewCustomerInfo: 'לחץ כאן להקמת לקוח חדש במערכת',
      newCustomerCreatedSuccessMessage:
        'הלקוח הוקם בהצלחה, הזמנה נשלחה למייל {0}',
      customersList: 'רשימת לקוחות',
      customersManagement: 'ניהול לקוחות',
      customerManagementInfo: 'צפייה, יצירה ועדכון לקוחות קיימים וחדשים במערכת',
      customersListInfo: 'צפייה ברשימת הלקחות הקיימים במערכת',
      customerName: 'שם הלקוח',
      businessId: 'מספר יישות (ח.פ./ע.מ./ע.ר.)',
      businessName: 'שם הספק',
      customerAdminEmail: 'כתובת מייל משתמש מנהל',
      createCustomerButtonTitle: 'צור לקוח חדש',
      authorizedDomain: 'דומיין מורשה',
      contactPersonName: 'שם איש קשר',
      contactPersonEmail: 'כתובת מייל איש קשר',
      contactPersonPhone: 'טלפון איש קשר',
      contactPerson: 'איש קשר',
      activeFeatures: 'תכונות פעילות',
      createSuperAdminUserTitle: 'הקמת מנהל מערכת',
      createSuperAdminUserSubtitle:
        'לחץ כאן ליצירת משתמש סופר מנהל במערכת קורס',
      newUserCreatedSuccessMessage: 'המשתמש הוקם בהצלחה',
      adminUsers: 'ניהול משתמשים',
      createSuperAdminUserButtonTitle: 'צור משתמש'
    },
    businessTypes: {
      authorizedDealer: 'עוסק מורשה',
      limitedCompany: 'חברה בע"מ',
      ngo: 'מלכ"ר',
      ngoVat: 'מלכ״ר חייב במע״מ',
      exemptDealer: 'עוסק פטור',
      partnership: 'שותפות',
      entityless: 'חסר ישות',
      entitylessWithVat: 'חסר ישות חייב במע״מ'
    },
    priceTypes: {
      noPrice: 'ללא מחיר קטלוגי, נקבע בהזמנה',
      withPriceChangeable: 'עם מחיר קטלוגי, ניתן לשינוי',
      withPriceUnchangeable: 'עם מחיר קטלוגי, לא ניתן לשינוי'
    },
    orderDocumentTypes: {
      bid: 'הצעת מחיר',
      contract: 'הסכם',
      script: 'תשריט',
      copy: 'צילום',
      wrote_quantities: 'כתב כמויות',
      insurance_policy: 'פוליסת ביטוח',
      other: 'אחר'
    },
    unitOfMeasureTypes: {
      kg: 'קילוגרם',
      hour: 'שעות',
      piece: 'יחידה'
    },
    customer: {
      autorizedDomainChangeHint:
        'על מנת לשנות את הדומיין שלך, יש ליצור קשר עם התמיכה שלנו ב- hello@corz.app',
      general: 'כללי',
      digitalArchiveTitle: 'ארכיון דיגיטלי',
      digitalArchiveViewTitle: 'צפייה בארכיון הדיגיטלי',
      digitalArchiveSubtitle: 'לחץ לצפייה בארכיון הדיגיטלי של ארגונך',
      digitalArchive: {
        export: 'ייצוא נתונים',
        documentId: 'מזהה מסמך',
        documentType: 'סוג מסמך',
        businessNumber: 'ח.פ./ע.מ./ע.ר.',
        documentDate: 'תאריך מסמך',
        objectCreationDate: 'תאריך יצירת אובייקט',
        creationDate: 'תאריך יצירה',
        objectNumber: 'מספר אובייקט',
        objectType: 'סוג אובייקט',
        createdBy: 'נוצר ע״י',
        note: 'שים לב! חותמים בארכיון דיגיטלי יכולים להיות אך ורק משתמשים בעלי הרשאה של <strong>דורש רכש</strong> או <strong>מורשה חתימה</strong>',
        setAsSigner: 'הגדר כחותם דיגיטלי',
        setAsSignerAgain: 'הגדר מחדש',
        removeSigner: 'ביטול חותם דיגיטלי',
        removeSignerPromptTitle: 'הסרת חותם דיגיטלי',
        removeSignerPromptMessage:
          'האם אתה בטוח שאתה רוצה להסיר את המשתמש {0} מרשימת החותמים?',
        isSigner: 'חתימה מוגדרת',
        isDigitalArchiveSigner: 'חותם ארכיון דיגיטלי',
        signerSetSuccess: 'המשתמש {0} הוגדר כחותם דיגיטלי בהצלחה!',
        isDigitalArchiveSignerHint:
          'משתמש זה מוגדר כחותם דיגיטלי.\nלאחר ביצוע אישור ע״י משתמש זה, המערכת תבצע חתימה דיגיטלית מאושרת על שם המשתמש הזה.',
        signerAuthTitle: 'אימות חותם דיגיטלי',
        signerAuthMessage:
          'אנא הזן את שם המשתמש והסיסמה שקיבלת מחברת Personal ID עבור המשתמש {0} בעל כתובת אימייל {1}',
        signerUsername: 'מזהה תעודה',
        signerPassword: 'סיסמת חתימה',
        signerAuthSubmitTitle: 'שמור פרטי חתימה עבור משתמש',
        filters: {
          documentCreationDate: 'תאריך יצירת המסמך',
          objectCreationDate: 'תאריך יצירת האובייקט'
        },
        docTypes: {
          order: 'הזמנה',
          submission: 'הגשה'
        }
      },
      orderDocumentBuilder: {
        fromCustomer: 'מאת: {0} ({1})',
        orderDetails: 'פרטי הזמנה',
        vendorDetails: 'פרטי הספק'
      },
      irsFileGen: {
        resultsTitle: 'פירוט סך סוגי הרשומות בקובץ BKMVDATA.TXT',
        irsFileFor: 'עבור:',
        irsFileSubtitle: 'מספר עוסק מורשה: {0}',
        irsFileSubtitle2: 'שם בית העסק: {0}',
        periodSelection: 'בחירת תקופות',
        fromDate: 'מתאריך',
        toDate: 'עד תאריך',
        fromDateToDate: 'מתאריך {0} עד תאריך {1}',
        fromPeriod: 'מתקופה',
        toPeriod: 'עד תקופה',
        eventDate: 'תאריך אירוע',
        balancePeriod: 'תקופת מאזן',
        recordCode: 'קוד רשומה',
        recordDesc: 'תיאור רשומה',
        recordsNum: 'סך רשומות',
        generateIrsFile: 'יצירת קבצי ממשק',
        irsFileGeneratedSuccessMessage: 'קובץ אחיד הופק בהצלחה',
        irsFileGenInfoMsg: 'הנתונים הופקו באמצעות פורטל הספקים של CorZ',
        irsCertNum: 'מספר תעודת הרישום: {0}',
        irsGenDateTime: 'בתאריך {0} ושעה {1}',
        irsGenAlert: 'ביצוע ממשק פתוח הסתיים בהצלחה',
        irsGenSavePath: 'הנתונים נשמרו בנתיב: {0}'
      },
      workflowManagementPage: {
        workflowName: 'שם סבב האישורים',
        workflowType: 'סוג סבב האישורים',
        updateDate: 'תאריך עדכון אחרון',
        stepsCount: '{0} שלבים',
        createWorkflowButtonTitle: 'יצירת סבב אישורים חדש'
      },
      catalogManagementPage: {
        createItemTitle: 'יצירת פריט חדש בקטלוג',
        updateItemTitle: 'עדכון פריט {0}',
        itemCode: 'קוד פריט',
        itemCodeHint: 'קוד הפריט בקטלוג',
        itemName: 'שם הפריט',
        itemNameHint: 'שם הפריט בקטלוג',
        itemPrice: 'מחיר הפריט',
        currency: 'מטבע',
        description: 'תיאור הפריט',
        priceType: 'סוג המחיר',
        unitOfMeasure: 'יחידת מידה',
        itemPriceHint: 'הזן מחיר בספרות בלבד',
        itemShortDescHint: 'תיאור קצר של הפריט (עד 200 תווים)',
        createButtonTitle: 'יצירת פריט חדש',
        createItemButtonTitle: 'צור פריט בקטלוג',
        updateItemButtonTitle: 'עדכן פריט בקטלוג',
        deleteCatalogItem: 'מחיקת פריט מהקטלוג',
        editCatalogItem: 'עריכת פריט',
        catalogItemCreatedSuccessMessage: 'הפריט {0} נוצר בהצלחה',
        catalogItemUpdatedSuccessMessage: 'הפריט {0} עודכן בהצלחה'
      },
      newOrderTypeDialog: {
        title: 'יצירת הזמנה חדשה'
      },
      manageAutomations: {
        orderAutomationsTitle: 'אוטומציות עבור הזמנות',
        invoicesAutomationsTitle: 'אוטומציות עבור הגשות במייל',
        orderAutomationEmailFieldLabel:
          'הזן את כתובת המייל ממנה המערכת הארגונית שלך <strong>תשלח</strong> את מסמכי הזמנות הרכש אלינו',
        invoicesAutomationsEmailFieldLabel:
          'עבור קבלת הגשות במייל בצורה אוטומטית, יש להנחות את הספק לשלוח מייל לכתובת הבאה:'
      },
      manageAutomationsTitle: 'ניהול אוטומציות',
      manageAutomationsSubtitle:
        'לחץ כאן לניהול והגדרת תהליכים אוטומטיים בחשבונך',
      manageInvoicesTitle: 'הגשות במייל',
      manageInvoicesItem: 'ניהול הגשות במייל',
      manageInvoicesTitleSubtitle: 'לחץ לניהול והגדרה של הגשות במייל בחשבונך',
      manageInvoicesSubtitle: 'לחץ לקליטה של חשבוניות ללא הזמנה שנשלחו מספקים',
      analyticalDataTitle: 'נתונים אנליטיים',
      analyticalDataSubtitle:
        'לחץ לצפייה בנתונים אנליטיים על גבי לוחות מחוונים',
      digitalArchiveSignersManagementTitle: 'חותמים בארכיון דיגיטלי',
      digitalArchiveSignersManagementSubtitle:
        'לחץ לנהל את רשימת חותמי הארכיון הדיגיטלי בחשבון שלך',
      workflowManagementTitle: 'ניהול והגדרת תהליכים עסקיים',
      workflowsManagementCardTitle: 'ניהול סבבי אישורים',
      workflowsManagementCardSubtitle:
        'לחץ ליצירה ועריכה של סבבי אישורים (Workflows) בארגון שלך',
      workflowOrderSubmissionsTitle: 'אישור הגשות',
      workflowOrderSubmissionsSubtitle:
        'לניהול סבב אישורים עבור הגשת חשבוניות להזמנות',
      manageCatalogTitle: 'ניהול קטלוג',
      manageCatalogSubtitle: 'לניהול הפריטים בקטלוג של הארגון שלך',
      contactPersonName: 'שם איש קשר',
      contactPersonEmail: 'כתובת מייל איש קשר',
      contactPersonPhone: 'מספר טלפון איש קשר',
      vendorsList: 'איתור ספקים',
      vendorsListInfo: 'לחץ על מנת לאתר ספק לפי מאפיינים',
      vendorsListInProgress: 'ספקים בתהליך הקמה',
      vendorsListInProgressInfo: 'ספקים שהוזמנו על ידכם ובתהליך הקמה',
      vendorsPendingList: 'ספקים שטרם נרשמו',
      vendorsPendingListInfo: 'ספקים שהוזמנו מהחשבון שלכם וטרם נרשמו לפורטל',
      vendorsSearch: 'חפש לפי שם או מספר עוסק',
      ordersPageTitle: 'הזמנות',
      companyLogo: 'לוגו',
      systemTags: 'תגיות מערכת',
      systemTagsHint: 'הקלד ולחץ על מקש ENTER להוספת התגית',
      systemTagsInfo:
        'הגדר תגיות הניתנות לשימוש בעת יצירת הזמנות, הגשות ועוד אובייקטים אחרים במערכת',
      businessId: 'מספר עוסק',
      supportNumber: 'מספר תמיכה בשירות לקוחות',
      activeFeatures: 'תכונות פעילות',
      submissionsPageTitle: 'ניהול הגשות מספקים',
      vendorsManagement: 'ניהול ספקים',
      vendorInvite: 'הזמנת ספק',
      vendorInviteInfo: 'לחץ להזמנת ספק חדש למערכת',
      signupTitle: 'רישום משתמש חדש',
      signupSubtitleAccountSpecific: 'עבור לקוח {0} עם הרשאה {1}',
      signupSubtitleNotAccountSpecific: 'עם הרשאה {0}',
      ordersTitle: 'ניהול הזמנות',
      activeOrders: 'הזמנות פעילות',
      activeOrdersInfo: 'לחץ כאן לצפייה בהזמנות פעילות',
      closedOrders: 'הזמנות סגורות',
      closedOrdersInfo: 'לחץ כאן לצפייה בהזמנות סגורות',
      cancelledOrders: 'הזמנות שבוטלו',
      cancelledOrdersInfo: 'לחץ כאן לצפייה בהזמנות שבוטלו',
      rejectedOrders: 'הזמנות שנדחו',
      rejectedOrdersInfo: 'לחץ כאן לצפייה בהזמנות שנדחו במהלך סבב האישורים',
      createNewOrder: 'הזמנה חדשה',
      uploadOrder: 'העלאת קובץ הזמנה קיים',
      createManualOrder: 'יצירת הזמנה חדשה',
      createManualOrderWithItems: 'יצירת הזמנה חדשה עם שורות',
      createNewOrderInfo: 'לחץ כאן להעלאת הזמנה חדשה',
      pendingOrders: 'הזמנות ממתינות לטיפול',
      pendingOrdersInfo: 'לחץ לצפייה בהזמנות הממתינות לטיפול',
      pendingApprovalOrders: 'הזמנות ממתינות לאישור',
      pendingApprovalOrdersInfo: 'לחץ לצפייה וניהול הזמנות הממתינות לאישורך',
      userManagementTitle: 'ניהול משתמשים וחשבון',
      usersManagement: 'ניהול משתמשים',
      usersManagementInfo: 'לחץ לצפייה וניהול של משתמשים ובעלי תפקידים בחשבון',
      inviteUser: 'הזמנת משתמש',
      inviteUserInfo: 'לחץ להזמנת בעל תפקיד חדש לחשבון',
      accountSettings: 'הגדרות חשבון',
      accountSettingsInfo: 'לחץ לצפייה וניהול חשבון הלקוח שלך',
      digitalArchiveSigners: 'ניהול חותמים בארכיון דיגיטלי',
      customization: 'התאמה אישית',
      customizationInfo: 'לחץ לביצוע התאמה אישית של חשבונך',
      irsFile: 'הפקת קבצים במבנה אחיד',
      irsFileInfo: 'לחץ להפקת קובץ אחיד עבור מס הכנסה',
      pendingVendorRegistration: 'ממתין לרישום ספק',
      pendingVendorRegistrationInfo:
        'הספק לא קיים במערכת, ברגע שהספק יירשם למערכת, הזמנה זו תוקצה אליו באופן אוטומטי',
      vendorLookup: {
        filters: {
          vendorId: 'מספר ספק',
          primaryCategory: 'קטגוריה ראשית',
          secondaryCategory: 'קטגוריה משנית',
          city: 'עיר'
        },
        showInvitedByMeVendors: 'הראה רק ספקים שהוזמנו על ידי',
        draftVendors: 'ספקים בתהליך הקמה',
        invitedVendors: 'ספקים שהוזמנו וטרם נרשמו',
        favoritesVendors: 'ספקים מועדפים',
        activeVendors: 'ספקים פעילים',
        invitationCancelled: 'ההזמנה בוטלה בהצלחה!'
      },
      orders: {
        orderShortDesc: 'כותרת הזמנה',
        orderDescription: 'תיאור',
        downloadOrderDocument: 'הורד מסמך הזמנה',
        orderApprovalSteps: {
          step1: 'יצירת הזמנה',
          step2: 'שליחה לאישור',
          step3: 'אישור מורשה חתימה',
          step4: 'אישור מחלקת רכש',
          step5: 'קליטה'
        },
        orderItemsPage: {
          rowNumber: 'מספר שורה',
          selectItem: 'בחר פריט',
          newItem: 'פריט חדש',
          itemNumber: 'מספר פריט',
          itemName: 'שם הפריט',
          supplyDate: 'תאריך אספקה',
          unitOfMeasure: 'יחידת מידה',
          quantity: 'כמות',
          quantityCredit: 'זיכוי כמות',
          quantityReported: 'כמות שדווחה',
          quantityLeft: 'יתרת כמות',
          price: 'מחיר יחידה',
          totalAmount: 'סה״כ במטבע הזמנה'
        },
        selectApprovalWorkflow: 'בחר סבב אישורים',
        accountManagerSelect: 'מנהל חשבות',
        filters: {
          vendor: 'ספק',
          issueDate: 'תאריך הוצאה גדול מ-',
          orderNumber: 'מספר הזמנה',
          submissionNumber: 'מספר הגשה',
          tags: 'תגיות',
          showOrdersPendingToMe: 'הראה הזמנות ממתינות לאישורי'
        },
        approveOrder: 'אישור הזמנה',
        rejectOrder: 'דחיית הזמנה',
        returnOrder: 'החזרה',
        orderGeneralDetails: 'פרטי הזמנה',
        orderDocument: 'מסמך הזמנה',
        orderFullDisplayTitle: 'צפייה בהזמנה',
        orderFullDisplaySubtitle: 'לחץ לצפייה בהזמנה המלאה',
        orderItems: 'שורות וסכום הזמנה',
        orderItemsNoData: 'לא קיימות שורות בהזמנה',
        orderApprovalWorkflow: 'סבב אישורים',
        summery: 'סיכום ושליחה',
        sendOrderButtonTitle: 'לחץ כאן לשליחה והפעלת ההזמנה',
        activatingOrder: 'מפעיל הזמנה...',
        createdAt: 'תאריך יצירה',
        orderServices: 'הגדרת שירותים',
        orderAutoAllocated: 'יוקצה אוטומטית לאחר יצירה',
        orderAutoAllocatedHint:
          'מספר ההזמנה עבור הזמנות שנוצרו בפורטל הינו מספר רץ ויוקצה אוטומטית לאחר שליחת ההזמנה או שמירת ההזמנה כטיוטה.',
        filtersBarTitle: 'סינון הזמנות',
        defineApprovalRound: 'הגדרת סבבי אישורים',
        defineOrderApprovalRound: 'סבב אישורים עבור הזמנה',
        defineAccountManagementRound: 'סבב אישורים עבור הנהלת חשבונות',
        defineOrderSubmissionApprovalRound: 'סבב אישורים עבור הגשה להזמנה',
        vendorSearchPlaceholder: 'חפש לפי שם או מספר עוסק',
        ordersCount: '{0} הזמנות',
        orderTitle: 'הזמנה {0}',
        orderSubmissionsCount: '{0} הגשות',
        orderDetailsForm: 'טופס פרטי הזמנה',
        orderAmountDetails: 'סכום הזמנה',
        orderAdditionalInfo: 'פרטים נוספים',
        orderNumber: 'מספר הזמנה',
        parentSubmissionNumber: 'מספר הגשת  אב',
        totalAmount: 'סכום הזמנה כולל',
        amountLeft: 'יתרת הזמנה',
        vatAmount: 'מע״מ',
        vatPercent: 'מע״מ: {0}%',
        totalAmountValue: 'סכום כולל: {0}',
        totalNetValue: 'סכום לפני מע״מ: {0}',
        vatValue: 'מע״מ: {0}',
        noVat: 'מע״מ אפס',
        netAmount: 'סכום לפני מע״מ',
        vendorId: 'מספר ספק (ח.פ./ע.מ./ע.ר.)',
        vendorName: 'שם הספק',
        orderDocument: 'מסמך הזמנה',
        viewOrderDocument: 'הזמנה',
        issueDate: 'תאריך הוצאה',
        reviewOrder: 'טיפול בהזמנה',
        viewOrder: 'הצג הזמנה',
        orderStatus: 'סטטוס הזמנה',
        shortDesc: 'תיאור',
        shortDescInfo: 'תיאור קצר של ההזמנה (מומלץ)',
        currency: 'מטבע הזמנה',
        selectCurrency: 'יש לבחור מטבע הזמנה',
        orderPurchaser: 'דורש רכש',
        orderAuthorizer: 'מורשה חתימה',
        createdByUser: 'נוצר ע״י',
        approvers: 'מאשרים',
        approvalRound: 'סבב אישורים',
        pendingApprovalBy: 'ממתין לאישור של',
        viewApprovalRound: 'לחץ לצפייה בסבב אישורים',
        selectOrderAuthorizer: 'בחר מורשה חתימה',
        selectOrderPurchaser: 'בחר דורש רכש',
        selectAccountManager: 'בחר מנהל חשבות',
        status: {
          new: 'הזמנה חדשה',
          new_hint: 'הזמנה חדשה שטרם נשלחה לאישור.\nהזמנה זו היא במצב טיוטה',
          new_predicted: 'הזמנה חדשה שעברה מילוי אוטומטי',
          new_predicted_hint:
            'הזמנה שעברה עיבוד באמצעות בינת מלאכותית על מנת לנסות ולהוציא פרטים רלוונטיים בצורה אוטומטית ללא התערבות בן אנוש.\nמומלץ לבצע השוואה מול המסמך המקורי בצד שמאל על מנת לוודא שהפרטים נכונים.',
          open: 'הזמנה פעילה',
          open_pending_vendor_registration: 'הזמנה פתוחה - ממתינה לרישום ספק',
          open_pending_vendor_registration_hint:
            'הזמנה פתוחה שמחכה לרישום ספק. ברגע שהספק ירשם למערכת הזמנה זו תחובר אליו באופן אוטומטי',
          pending_approval: 'ממתין לאישור',
          pending_approval_hint: 'הזמנה בתהליך סבב אישורים ע״י מורשי החתימה',
          returned: 'הוחזרה',
          returned_hint: 'הזמנה הוחזרה ע״י מאשר ההזמנה',
          rejected: 'נדחתה',
          rejected_hint: 'הזמנה נדחתה ע״י אחד המאשרים',
          closed: 'הזמנה סגורה',
          closed_hint: 'הזמנה שהייתה פעילה בעבר ונסגרה כי נוצלה במלואה',
          cancelled: 'הזמנה מבוטלת',
          cancelled_hint: 'הזמנה שבוטלה מהסיבה:\n{0}'
        },
        creatingOrder: 'מעלה מסמך הזמנה...',
        autoExtractDataLabel: 'נסה לייצא נתונים באופן אוטומטי',
        autoExtractDataTooltip:
          'המערכת תבצע קריאה של הנתונים מהקובץ ותנסה לנבא באילו נתונים ניתן להשתמש ותעדכן את ההזמנה בהתאם.\nאנו ממליצים לבצע בדיקה שאכן כל הנתונים שנובאו נכונים ותואמים.',
        processingOrder: 'מבצע עיבוד להזמנה...',
        saveAndOpenOrder: 'שמירה והפעלה',
        saveAndOpenHint: 'לחץ לשמירה והפעלה של הזמנה זו במערכת',
        saveAndSendToApproval: 'שמירה ושליחה לאישור',
        saveAndSentToApprovalHint: 'לחץ לשמירה ושליחת ההזמנה לאישור',
        saveOrderDraft: 'שמירת הזמנה',
        saveOrderDraftHint: 'שמירה בלבד, ללא הפעלה של ההזמנה',
        revertChanges: 'בטל שינויים',
        revertChangesHint:
          'בטל שינויים מקומיים שנעשו וחזור לפרטי ההזמנה המקוריים',
        deleteOrder: 'מחיקת הזמנה',
        cancelOrder: 'ביטול הזמנה',
        closeOrder: 'סגירת הזמנה',
        orderApprovalHistory: 'היסטוריית אישורים עבור הזמנה',
        vendorNotFound: 'ספק לא קיים במערכת',
        vendorNotFoundHint:
          'נראה שהספק לא קיים במערכת אצלנו. אל דאגה, עדיין באפשרותך להמשיך כרגיל וברגע שהספק יירשם למערכת אנחנו נדאג לצוות את ההזמנה אליו. באפשרותך גם להזמין אותו להירשם במערכת על ידי לחיצה על כפתור ״הזמן ספק״',
        inviteVendor: 'הזמן ספק',
        vendor: 'ספק',
        cancelOrderNote:
          'שים לב, לאחר ביטול הזמנה לא תהיה אפשרות לקלוט הזמנה מספר {0} שוב',
        closeOrderNote:
          'שים לב, לאחר סגירת ההזמנה לא תהיה אפשרות לקלוט הזמנה מספר {0} שוב',
        cancellationReason: 'סיבת הביטול',
        cancellationReasonPlaceholder: 'רשום פירוט למה ברצונך לבטל הזמנה זו...',
        closingReason: 'סיבת הסגירה',
        cancelOrderDialogTitle: 'ביטול הזמנה מספר {0}',
        closeOrderDialogTitle: 'סגירת הזמנה מספר {0}',
        cancelOrderButtonTitle: 'לחץ לביטול ההזמנה',
        closeOrderButtonTitle: 'לחץ לסגירת ההזמנה',
        orderCancelledSuccess: 'ההזמנה בוטלה בהצלחה',
        orderUpdateSuccess: 'ההזמנה נשמרה בהצלחה',
        orderCreatedSuccess: 'ההזמנה נוצרה בהצלחה',
        orderClosedSuccess: 'ההזמנה נסגרה בהצלחה',
        orderChangedPrompt: {
          title: 'שים לב!',
          message:
            'המערכת זיהתה שבוצעו שינויים בהזמנה ללא שמירה.\nהאם אתה בטוח שברצונך להמשיך ללא שמירה?',
          doneButtonTitle: 'המשך ללא שמירה'
        },
        orderClosedMessage: 'ההזמנה נסגרה ב- {0} ע״י {1}.',
        orderRejectedMessage: 'ההזמנה נדחתה בתאריך {0} ע״י {1} מהסיבה: {2}',
        orderClosedReason: 'סיבת סגירה: {0}',
        orderRejectReason: 'סיבת דחייה: {0}',
        orderCancelledBy: 'בוטלה ע״י',
        actions: 'פעולות',
        returnTypes: {
          invalid_quantity: 'כמות שגויה',
          invalid_price: 'מחיר שגוי',
          other: 'אחר'
        },
        rejectTypes: {
          duplicate_order: 'הזמנה כפולה',
          invalid_vendor: 'ספק שגוי',
          budget_exceed: 'חריגה מהיקף התקציב',
          project_cancelled: 'פרוייקט בוטל',
          other: 'אחר'
        },
        approveOrderActionTitle: 'אישור הזמנה',
        returnOrderActionTitle: 'החזרה לדורש הרכש',
        rejectOrderActionTitle: 'דחיית הזמנה',
        decisionActionButtons: {
          approve: 'אישור הזמנה',
          reject: 'דחיית הזמנה',
          return: 'החזרת הזמנה לדורש הרכש'
        }
      },
      userManagement: {
        userFullName: 'שם מלא',
        userEmail: 'כתובת דוא״ל',
        userRoles: 'תפקידים',
        inviteUser: 'הזמן משתמש',
        inviteUserTitle: 'הזמנת משתמש חדש',
        invitedBy: 'הוזמן ע״י',
        invitedAt: 'הוזמן בתאריך',
        role: 'תפקיד',
        activeUsers: 'משתמשים פעילים',
        pendingInvites: 'משתמשים ממתינים',
        inviteResent: 'ההזמנה נשלחה בהצלחה',
        assignUserRoleTitle: 'הוספת הרשאה למשתמש',
        assignRoleFor: 'עבור משתמש',
        assignRolePlaceholder: 'בחר תפקיד',
        roleRemovedSuccessMessage: 'התפקיד הוסר בהצלחה',
        roleAssignedSuccessMessage: 'התפקיד הוקצה בהצלחה',
        actions: {
          suspend: 'השהה משתמש',
          resentInvite: 'שלח הזמנה מחדש',
          cancelInvite: 'בטל הזמנה',
          assignNewRole: 'הוסף הרשאה חדשה'
        },
        roles: {
          customerAdmin: 'מנהל פורטל',
          customerPurchaser: 'דורש רכש',
          customerAuthorizer: 'מורשה חתימה',
          customerObserver: 'צופה',
          customerCatalogAdmin: 'מנהל קטלוג',
          customerAccountManager: 'מנהל/ת חשבונות',
          digitalArchiveSigner: 'חותם ארכיון דיגיטלי',
          customerDigitalArchiveViewer: 'צופה בארכיון דיגיטלי',
          vendor: 'ספק'
        },
        removeRoleDialogTitle: 'הסרת הראשה',
        removeRoleDialogMessage:
          'האם אתה בטוח שברצונך להסיר הרשאה {0} למשתמש {1}',
        removeRoleDialogDoneButton: 'הסר הרשאה'
      },
      orderlessSubmissions: {
        pendingSubmissions: 'הגשות ממתינות לקליטה',
        orderlessSubmissions: 'הגשות במייל',
        orderlessSubmission: 'הגשה ללא הזמנה',
        defineSubmissionWorkflow: 'סבב אישורים עבור הגשה ללא הזמנה',
        defineSubmissionAccountantWorkflow: 'סבב אישורים עבור הנהלת חשבונות',
        orderlessAdditionalDetails: 'פרטים נוספים (עבור הגשות במייל)'
      },
      vendorSubmissions: {
        submissionHistory: 'היסטוריית הגשה',
        exportSubmissions: 'יצוא הגשות',
        submissionNumber: 'מספר הגשה',
        exportSubmissionsInfo: 'לחץ להורדת הגשות לקובץ',
        vendorSubmissionsTitle: 'ניהול הגשות מספקים',
        vendorSubmissionsObserveTitle: 'צפייה בהגשות מספקים',
        submissionStatus: 'סטטוס הגשה',
        documentStatus: 'סטטוס מסמך',
        submissionHistoryCreatedBy: 'ע״י <strong>{0}</strong>',
        approvedBy: 'אושר ע״י <strong>{0}</strong>',

        pendingSubmissionsTitle: 'הגשות ממתינות לטיפולי',
        pendingSubmissionsObserveTitle: 'הגשות ממתינות לטיפול',
        pendingSubmissionsSubtitle: 'לחץ כאן על מנת לטפל בהגשות מספקים',
        pendingSubmissionsObserveSubtitle: 'לחץ על מנת לצפות בהגשות מספקים',
        handeledSubmissionsTitle: 'הגשות מספקים',
        handeledSubmissionsSubtitle:
          'לחץ על מנת לראות הגשות מספקים שאינן ממתינות לאישורך',
        handeledSubmissionsObserverTitle: 'הגשות שטופלו',
        handeledSubmissionsObserverSubtitle: 'לחץ לצפייה בהגשות שטופלו',
        pendingAuthorizerSubmissionsTitle: 'לאיהגשות ממתינות שור רכש',
        pendingAuthorizerSubmissionsSubtitle:
          'לחץ כאן על מנת לאישור הגשות שהגיעו מספקים',
        pendingPurchaserSubmissionsTitle: 'הגשות ממתינות לטיפול כספים',
        submissionNumber: 'מספר הגשה',
        shortDesc: 'תיאור קצר',
        orderTags: 'תגיות',
        vendorName: 'שם ספק',
        handleSubmission: 'לחץ לטיפול בהגשה זו',
        decisionActionButtons: {
          approve: 'אישור הגשה',
          approveAndSendToPurchaser: 'אישור ושליחה למחלקת רכש',
          reject: 'דחיית הגשה',
          return: 'החזרה לספק'
        },
        submissionSteps: {
          step1: 'יצירת הגשה',
          step2: 'שליחה לאישור',
          step3: 'אישור מורשה חתימה',
          step4: 'אישור מחלקת רכש',
          step5: 'קליטה'
        },
        approveActionTitle: 'אישור הגשה',
        submissionRejectedDue: 'הגשה נדחתה מהסיבה: {0}',
        returnType: 'סיבת החזרה',
        returnTypeSelect: 'בחר סיבת החזרה',
        returnTypes: {
          deliveryCertMissing: 'חסרה תעודת משלוח',
          executionReportMissing: 'חסר דו״ח ביצוע',
          referenceMissing: 'חסרות אסמכתאות',
          accountingBookCertificateExpired: 'אישור ניהול ספרים פג תוקף',
          duplicateSubmission: 'הגשה כפולה',
          invalidQuantity: 'כמות שגויה',
          invalidAmount: 'מחיר שגוי',
          other: 'אחר'
        },
        returnActionTitle: 'החזרה לספק',
        rejectType: 'סיבת דחייה',
        pendingApprovalTitle: 'ממתין לאישור',
        pendingApprovalSubtitle: 'ההגשה ממתינה לאישור ע״י הגורם המאשר',
        rejectTypeSelect: 'בחר סיבת דחייה',
        rejectTypes: {
          invoiceUnmatched: 'חשבונית לא תואמת את השירות',
          invalidDate: 'תאריך שגוי',
          invalidQuantity: 'כמות שגויה',
          invalidAmount: 'סכום שגוי'
        },
        rejectActionTitle: 'דחיית הגשה',
        note: 'הערה',
        noteInfo: 'הערות נוספות עבור הגשה זו',
        noNotes: 'אין הערות',
        submissionReturnedSuccess: 'ההגשה הוחזרה לספק בהצלחה',
        submissionRejectedSuccess: 'ההגשה נדחתה בהצלחה',
        submissionApprovedSuccess: 'ההגשה אושרה בהצלחה'
      }
    },
    invite: {
      invitationTitle: 'הזמנה עבור {0}',
      invitationMessage:
        'הזמנה זו נשלחה אליך ע״י {0}.\nלאחר אישור ההזמנה, ינתנו לך ההרשות הבאות: {1}.\nלאישור וקבלת ההרשאות, יש ללחוץ על כפתור ״אישור וקבלת ההזמנה״',
      inviteSentSuccess: 'הזמנה ל-{0} נשלחה בהצלחה'
    }
  }
};

export default messages;
