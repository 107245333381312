<template>
  <v-dialog
    v-on="$listeners"
    v-bind="$attrs"
    persistent
    scrollable
    class="dialog"
  >
    <v-card
      :style="{
        overflow: allowOverflow ? 'auto' : 'hidden'
      }"
      :loading="loading"
      rounded="lg"
      class="pb-3"
    >
      <v-card-title class="d-flex justify-space-between">
        <span class="text-h6 flex-grow-1" :class="`${titleColor}--text`">{{
          title
        }}</span>
        <v-btn
          v-if="closeable"
          color="textPrimary"
          text
          dark
          :disabled="loading"
          @click="$emit('close')"
          style="position: absolute; top: 13px; left: 5px"
          ><v-icon :size="22" color="button">{{ mdiClose }}</v-icon></v-btn
        >
      </v-card-title>

      <v-divider />

      <v-card-text :class="!inset ? 'pa-0 mt-1' : 'px-4 py-4 mt-1'">
        <slot />
      </v-card-text>
      <v-card-actions v-if="showActions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';
export default {
  name: 'CzDialog',
  props: {
    title: {
      type: String,
      required: false
    },
    titleColor: {
      type: String,
      default: 'textPrimary'
    },
    allowOverflow: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    inset: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiClose
    };
  }
};
</script>

<style lang="scss" scoped>
.dialog {
  z-index: 10000;
}
</style>
