import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/core/store';
import { subject } from '@casl/ability';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  },

  {
    path: '/',
    name: 'home',
    component: () => import('../pages/Home.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      checkUserLoggedInAndVerified(to, from, next, true);
    },
    // redirect: async (to) => {
    //   return {
    //     name: 'super-admin-home'
    //   };
    //   // const { abilities } = await getCurrentUser();
    //   // if (abilities.can('manage', 'vendor-admin')) {
    //   //   return {
    //   //     name: 'vendor-admin'
    //   //   };
    //   // } else if (abilities.can('manage', 'super-admin')) {
    //   //   // route user to super admin dashboard
    //   //   return {
    //   //     name: 'super-admin-home'
    //   //   };
    //   // }
    // },
    children: [
      {
        path: '404',
        name: 'not-found',
        component: () => import('../pages/NotFoundPage.vue')
      },
      {
        path: 'edit-profile',
        name: 'edit-profile',
        component: () => import('@/domain/auth/pages/EditProfilePage.vue')
      },
      {
        path: 'customer',
        name: 'customer',
        component: () => import('@/domain/customer/pages/CustomerHomePage.vue'),
        children: [
          {
            path: 'analytics',
            name: 'customer-analytics',
            component: () =>
              import('@/domain/customer/pages/CustomerAnalyticsPage.vue')
          },
          {
            path: 'digital-archive',
            name: 'customer-digital-archive',
            component: () =>
              import('@/domain/customer/pages/DigitalArchivePage.vue')
          },
          {
            path: 'orders',
            name: 'customer-orders',
            component: () => import('@/domain/customer/pages/OrdersPage.vue'),
            props: true
          },
          {
            path: 'vendor/:id',
            name: 'customer-vendor-details',
            component: () =>
              import('@/domain/customer/pages/VendorDetailsPage.vue'),
            props: true
          },
          {
            path: 'orders/:id',
            name: 'customer-order',
            component: () => import('@/domain/customer/pages/OrderPage.vue'),
            props: true,
            children: [
              {
                path: 'build',
                name: 'order-build',
                component: () =>
                  import('@/domain/customer/pages/BuildOrderPage.vue'),
                props: true
              },
              {
                path: 'decision',
                name: 'order-decision',
                component: () =>
                  import('@/domain/customer/pages/OrderDecisionPage.vue'),
                props: true
              },
              {
                path: 'display',
                name: 'order-display',
                component: () =>
                  import('@/domain/customer/pages/OrderDecisionPage.vue'),
                props: true
              }
            ]
          },

          {
            path: 'account',
            name: 'customer-account-settings',
            component: () =>
              import('@/domain/customer/pages/AccountSettings.vue'),
            props: false
          },
          {
            path: 'digital-archive-signers',
            name: 'digital-archive-signers',
            component: () =>
              import('@/domain/customer/pages/DigitalArchiveSignersPage.vue'),
            props: false
          },
          {
            path: 'manage-automations',
            name: 'customer-manage-automations',
            component: () =>
              import('@/domain/customer/pages/ManageAutomationsPage.vue')
          },
          {
            path: 'vendors-lookup',
            name: 'customer-vendors-lookup',
            props: true,
            component: () =>
              import('@/domain/customer/pages/VendorsLookupPage.vue')
          },
          {
            path: 'customize',
            name: 'customer-account-customization',
            component: () =>
              import('@/domain/customer/pages/AccountCustomizationPage.vue'),
            props: false
          },
          {
            path: 'submissions',
            name: 'customer-submissions',
            component: () =>
              import('@/domain/customer/pages/CustomerSubmissionsPage.vue'),
            props: true
          },
          {
            path: 'submissions/orderless',
            name: 'customer-submissions-orderless',
            component: () =>
              import('@/domain/customer/pages/CustomerInvoicesPage.vue')
          },
          {
            path: 'submission/:submissionId/:type?',
            name: 'customer-submission',
            component: () => import('@/domain/vendor/pages/SubmissionPage.vue'),
            props: true
          },

          {
            path: 'users-management',
            name: 'users-management',
            component: () =>
              import('@/domain/customer/pages/UsersManagementPage.vue'),
            beforeEnter: async (to, from, next) => {
              const { abilities } = await getCurrentUser();
              if (abilities.can('manage', 'users')) {
                next();
              } else {
                next({
                  name: 'home'
                });
              }
            }
          },
          {
            path: 'workflows-management',
            name: 'customer-workflows-management',
            component: () =>
              import('@/domain/customer/pages/WorkflowsManagementPage.vue')
          },
          {
            path: 'catalog-management',
            name: 'customer-catalog-management',
            component: () =>
              import('@/domain/customer/pages/CatalogManagementPage.vue')
          },
          {
            path: 'irs-file-gen',
            name: 'irs-file',
            component: () =>
              import('@/domain/customer/pages/GenerateIrsFilePage.vue')
          }
        ]
      },
      {
        path: 'vendor/:id',
        name: 'vendor-home',
        component: () => import('@/domain/vendor/pages/VendorHomePage.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
          const { abilities } = await getCurrentUser();
          if (abilities.can('read', 'vendors')) {
            next();
          } else {
            next({
              name: 'not-found'
            });
          }
        },
        children: [
          {
            path: 'orders',
            name: 'vendor-orders',
            props: true,
            component: () =>
              import('@/domain/vendor/pages/VendorOrdersPage.vue')
          },
          {
            path: 'submissions',
            name: 'vendor-submissions',
            props: true,
            component: () =>
              import('@/domain/vendor/pages/VendorSubmissionsPage.vue')
          },
          {
            path: 'submission/:submissionId',
            name: 'vendor-submission',
            component: () => import('@/domain/vendor/pages/SubmissionPage.vue'),
            props: true
          }
        ]
      },
      {
        path: 'vendor/onboarding/:id',
        name: 'vendor-onboarding',
        component: () => import('@/domain/vendor/pages/VendorOnBoarding.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
          const { abilities, account } = await getCurrentUser();
          if (to.params?.id === 'new' && abilities.can('create', 'vendors')) {
            next();
          } else if (
            abilities.can(
              'update',
              subject('vendors', {
                accountId: account._id
              })
            )
          ) {
            next();
          } else {
            next({
              name: 'not-found'
            });
          }
        }
      },
      {
        path: 'super-admin',
        name: 'super-admin-home',
        component: () =>
          import('@/domain/super-admin/pages/SuperAdminHome.vue'),
        children: [
          {
            path: 'vendors-lookup',
            name: 'super-admin-vendors-lookup',
            props: true,
            component: () =>
              import('@/domain/customer/pages/VendorsLookupPage.vue')
          },
          {
            path: 'workflows',
            name: 'super-admin-workflow-management',
            props: true,
            component: () =>
              import(
                '@/domain/super-admin/pages/ManageCustomerWorkflowsPage.vue'
              )
          },
          {
            path: 'manage-vendors',
            name: 'manage-vendors',
            props: true,
            component: () =>
              import('@/domain/super-admin/pages/ManageVendorsPage.vue')
          },
          {
            path: 'manage-vendors/:id',
            name: 'manage-vendor',
            props: true,
            component: () =>
              import('@/domain/super-admin/pages/ManageVendorPage.vue')
          },
          {
            path: 'customers',
            name: 'customers-list',
            component: () =>
              import('@/domain/super-admin/pages/CustomersListPage.vue')
          },
          {
            path: 'vendor-categories',
            name: 'super-admin-vendors-categories',
            component: () =>
              import(
                '@/domain/super-admin/pages/ManageVendorCategoriesPage.vue'
              )
          }
        ],
        beforeEnter: async (to, from, next) => {
          const { abilities } = await getCurrentUser();
          if (abilities.can('manage', 'vendors')) {
            next();
          } else {
            next({
              name: 'not-found'
            });
          }
        }
      }
    ]
  },
  {
    path: '/invite',
    name: 'invite',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/domain/invite/pages/AcceptInviteHomePage.vue'),
    children: [
      {
        path: 'accept/:id',
        name: 'accept-invite',
        props: true,
        component: () => import('@/domain/invite/pages/AcceptInvite.vue')
      }
    ],
    beforeEnter: async (to, from, next) => {
      await getCurrentUser();
      next();
    }
  },
  {
    path: '/auth',
    name: 'auth',
    redirect: {
      name: 'login'
    },
    meta: {
      requiresAuth: false,
      isAuth: true
    },
    component: () => import('@/domain/auth/pages/AuthHomePage.vue'),
    children: [
      // {
      //   path: 'signup',
      //   name: 'signup',
      //   component: () => import('@/domain/auth/pages/SignupPage.vue')
      // },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/domain/auth/pages/LoginPage.vue')
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import('@/domain/auth/pages/ResetPasswordPage.vue')
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/domain/auth/pages/ForgotPassword.vue')
      }
    ],
    beforeEnter: async (to, from, next) => {
      const { user } = await getCurrentUser();
      // if user is logged in, we can navigate it to home
      if (user) {
        next({
          name: 'home'
        });
      } else {
        next();
      }
    }
  },
  {
    path: '/auth',
    component: () => import('../layouts/AuthLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'verification',
        name: 'verification',
        component: () => import('@/domain/auth/pages/VerificationPage.vue')
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

const checkUserLoggedInAndVerified = async (
  to,
  from,
  next,
  nextOnSuccess = false
) => {
  const { user } = await getCurrentUser();
  // if user is not logged in
  // route user to login page
  if (!user) {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    });

    return false;
  }

  // if user is logged in and it has not been verified
  // route to the verificaiton page
  if (!user.isVerified) {
    next({
      name: 'verification'
    });
    return false;
  }

  // if next on success flag is true, we will allow user to continue route
  if (nextOnSuccess) {
    next();
  }
  // otherwise, allow user to continue
  // we should not call next here because we want to allow
  return true;
};

const getCurrentUser = async () => {
  return store.dispatch('auth/authenticate', {}, { root: true });
};

export default router;
