<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :open-on-click="false"
    transition="scale-transition"
    offset-y
    :min-width="300"
    :nudge-top="-10"
  >
    <template v-slot:activator="{ on, attrs }">
      <cz-input
        v-model="inputDate"
        v-bind="attrs"
        v-on="on"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        outlined
        :clearable="!readonly"
        :dense="dense"
        :disabled="disabled"
        :append-icon="!readonly ? mdiCalendar : null"
        @click:append="dateMenu = true"
        @click="onDateInputClicked"
        :readonly="readonly"
        validate-on-blur
        :hide-details="hideDetails"
        :label="label"
        @blur="$emit('blur')"
        v-mask="mask"
      />
    </template>

    <div class="d-flex flex-column white" style="gap: 1rem; z-index: 1000">
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :max="maxDate"
        :min="minDate"
        :prev-icon="mdiChevronLeft"
        :next-icon="mdiChevronRight"
        validate-on-blur
        :readonly="readonly"
        :type="type"
        :range="range"
        color="info"
        locale="he"
      />
      <div class="d-flex justify-end align-center py-2 px-2" style="gap: 1rem">
        <cz-button
          title="איפוס"
          color="textPrimary"
          text
          @click="date = null"
        />
        <cz-button
          title="אישור"
          color="info"
          text
          :disabled="!date"
          @click="onDateSelected"
        />
      </div>
    </div>
  </v-menu>
</template>

<script>
import CzInput from './CzInput';
import CzButton from './CzButton.vue';
import { mdiCalendar, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { format, parse, isValid } from 'date-fns';

export default {
  name: 'CzDatePicker',
  components: {
    CzInput,
    CzButton
  },
  props: {
    /**
     * The current date value
     * in ISO8601 format YYYY-mm-dd or YYYY-mm
     */
    value: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    minDate: {
      type: String
    },
    range: {
      type: Boolean
    },
    maxDate: {
      type: String
    },
    hideDetails: {
      type: Boolean
    },
    openMenuOnClick: {
      type: Boolean
    },
    type: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    /**
     * if this is true then the picker will start with year then month and then day
     * to allow the user to easily pick a date
     * otherwise, the date picker will be opended in a day mode
     */
    interactive: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mask() {
      if (this.type === 'month') {
        return '##/####';
      } else {
        return '##/##/####';
      }
    }
  },
  created() {
    if (this.value) {
      if (this.value instanceof Date && !isNaN(this.value)) {
        this.inputDate = format(this.value, this.dateFormat);
        this.date = format(this.value, 'yyyy-MM-dd');
        this.localDate = this.date;
      } else {
        let date = parse(this.value, this.dateFormat, new Date());
        this.inputDate = format(date, this.dateFormat);
        this.date = format(date, 'yyyy-MM-dd');
        this.localDate = this.date;
      }
    }
  },
  data() {
    return {
      mdiCalendar,
      mdiChevronLeft,
      mdiChevronRight,
      activePicker: null,
      date: null,
      localDate: null,
      inputDate: '',
      dateMenu: false
    };
  },
  methods: {
    onDateSelected() {
      if (!this.date || !this.date?.length) {
        this.inputDate = '';
        this.date = null;
      } else {
        if (this.range) {
          this.inputDate = `${format(
            new Date(this.date[0]),
            this.dateFormat
          )} - ${format(new Date(this.date[1]), this.dateFormat)}`;
        } else {
          this.inputDate = format(new Date(this.date), this.dateFormat);
        }
      }

      this.dateMenu = false;
    },
    onDateChanged(dateString) {
      // this.dateMenu = false;
      if (!dateString || !dateString?.length) {
        this.inputDate = '';
        this.date = null;
      } else {
        if (this.range) {
          this.inputDate = `${format(
            new Date(dateString[0]),
            this.dateFormat
          )} - ${format(new Date(dateString[1]), this.dateFormat)}`;
        } else {
          this.inputDate = format(new Date(dateString), this.dateFormat);
        }
      }
    },
    onDateInputClicked() {
      this.dateMenu = this.openMenuOnClick;
    }
  },
  watch: {
    inputDate(val) {
      debugger;
      if (isValid(parse(val, this.dateFormat, new Date()))) {
        const d = parse(val, this.dateFormat, new Date());
        this.date = format(d, 'yyyy-MM-dd');
      }

      if (this.date !== this.localDate) {
        this.$emit('input', val);
      }
    },
    dateMenu(val) {
      // in interactive mode we start with year, then month and then day
      if (this.interactive) {
        val && setTimeout(() => (this.activePicker = 'YEAR'));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
