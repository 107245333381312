<template>
  <div class="d-flex flex-column" style="row-gap: 2px">
    <div :class="labelClass" class="d-flex align-center textPrimary--text">
      <cz-icon
        v-if="iconSrc"
        color="textPrimary lighten-3"
        :src="iconSrc"
        :size="16"
        class="ml-2"
      />
      <div>{{ required ? `* ${label}` : `${label}` }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mdiInformation } from '@mdi/js';
import CzIcon from './CzIcon.vue';
export default {
  name: 'CzFormField',
  components: {
    mdiInformation,
    CzIcon
  },
  props: {
    label: {
      type: String,
      required: false
    },
    labelColor: {
      type: String,
      default: 'textSecondary'
    },
    large: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: undefined
    },
    iconSrc: {
      type: String
    }
  },
  computed: {
    labelClass() {
      return {
        [`${this.labelColor}--text`]: true,
        'text-caption': !this.large,
        'text-subtitle-1': this.large,
        'font-weight-semibold': this.large
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  color: #849baf;
}
</style>
