<template>
  <div class="d-flex">
    <bz-spinner
      v-if="loading"
      :size="18"
      hide-title
      style="height: 46px; margin-right: 14px"
    />
    <v-checkbox
      v-else
      v-bind="$attrs"
      v-on="$listeners"
      :readonly="readonly"
      :disabled="disabled"
      :color="color"
      :on-icon="mdiCheckboxMarked"
      :off-icon="mdiCheckboxBlankOutline"
      hide-details
      class="ma-0 pa-0"
    />
    <div class="text-subtitle-2" :class="`${color}--text`" v-text="label" />
  </div>
</template>

<script>
import CzSpinner from './CzSpinner';
import { mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js';
export default {
  name: 'CzCheckbox',
  components: {
    CzSpinner
  },
  props: {
    color: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    label: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiCheckboxMarked,
      mdiCheckboxBlankOutline
    };
  }
};
</script>

<style lang="scss" scoped></style>
