import {
  required,
  email,
  max,
  min,
  confirmed,
  min_value,
  max_value,
  regex,
  mimes,
  length
} from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';
import { parsePhoneNumber } from 'awesome-phonenumber';
import isFQDN from 'validator/lib/isFQDN';
import isURL from 'validator/lib/isURL';
import isAlpha from 'validator/lib/isAlpha';
import {
  parse,
  parseISO,
  isValid,
  isPast,
  endOfDay,
  startOfDay,
  isFuture
} from 'date-fns';
import { he } from 'date-fns/locale';

export const setup = (i18n) => {
  extend('required', {
    ...required,
    message: (field, values) => i18n.t('validators.required', [field])
  });

  extend('mimes', {
    ...mimes,
    message: (name) => i18n.t('validators.fileTypeNotSupported')
  });

  extend('date_format', {
    message: () => i18n.t('validators.invalidDate'),
    validate(value, [format]) {
      if (value instanceof Date && !isNaN(value)) {
        return true;
      }
      const parsed =
        format === 'iso'
          ? parseISO(value)
          : parse(value, format, new Date(), { locale: he });
      const valid = isValid(parsed);
      return valid;
    }
  });

  extend('date_present', {
    message: () => i18n.t('validators.presentDate'),
    validate(value, [format]) {
      if (value instanceof Date && !isNaN(value)) {
        return !isPast(endOfDay(value));
      }
      const parsed = parse(value, format, new Date(), { locale: he });
      const valid = isValid(parsed) && !isPast(endOfDay(parsed));
      return valid;
    }
  });

  extend('date_future', {
    message: () => i18n.t('validators.futureDate'),
    validate(value, [format]) {
      if (value instanceof Date && !isNaN(value)) {
        return isFuture(startOfDay(value));
      }
      const parsed = parse(value, format, new Date(), { locale: he });
      const valid = isValid(parsed) && isFuture(startOfDay(parsed));
      return valid;
    }
  });

  extend('phoneNumber', {
    message: (field) => i18n.t('validators.phone'),
    validate(value) {
      return new Promise((resolve) => {
        const pn = parsePhoneNumber(value, { regionCode: 'IL' });
        resolve({ valid: pn.valid });
      });
    }
  });

  extend('domain', {
    message: (field) => i18n.t('validators.domain'),
    validate(value) {
      return isFQDN(value);
    }
  });

  extend('email', {
    ...email,
    message: () => i18n.t('validators.email')
  });

  extend('max', {
    ...max,
    message: (field, { length }) => {
      return i18n.t('validators.maxCharacters', [field, length]);
    }
  });

  extend('min', {
    ...min,
    message: (field, { length }) => {
      return i18n.t('validators.minCharacters', [field, length]);
    }
  });

  extend('min_value', {
    ...min_value,
    message: (field, { min }) => {
      return i18n.t('validators.minValue', [min]);
    }
  });

  extend('max_value', {
    ...max_value,
    message: (field, { max }) => {
      return i18n.t('validators.maxValue', [max]);
    }
  });
  extend('length', {
    ...length,
    message: (field, { length }) => {
      return i18n.t('validators.length', [field, length]);
    }
    // message: '{_field_} must contain at least {length} items'
  });

  extend('regex', regex);

  // const isUrl = (value) => {
  //   if (!value) {
  //     return true;
  //   }

  //   const regexap = /(^$|(http(s)?:\/\/)([\w-]+\.)+[\w-]+([\w- ;,.\/?%&=]*))/;
  //   return regexap.test(value);
  //   // const regexp =
  //   //   /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  //   // return regexp.test(value);
  // };

  extend('url', {
    computesRequired: true,
    validate: (value) => {
      if (!value) {
        return true;
      }

      return isURL(value);
    },
    message: () => i18n.t('validators.url')
  });

  extend('alpha', {
    computesRequired: true,
    validate: (value) => {
      if (!value) {
        return true;
      }

      return isAlpha(value);
    },
    message: () => i18n.t('validators.url')
  });
  // Password valdator

  extend('fullName', {
    computesRequired: true,
    validate: (value) => {
      const parts = value.split(' ');
      return parts?.length >= 2;
    },
    message: () => {
      return i18n.t('validators.fullName');
    }
  });

  extend('password', {
    computesRequired: true,
    validate: (value) => {
      const regex =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/i;
      return regex.test(value);
    },
    message: () => {
      return `${i18n.t('validators.passwordStrength')}\n${i18n.t(
        'validators.passwordStrengthHint'
      )}`;
    }
  });

  extend('confirmed', {
    ...confirmed,
    message: i18n.t('auth.passwordNotMatched')
  });
};
